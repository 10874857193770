import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Learn = () => {
  const title = 'Learn Wing Chun';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <ul>
        <li>
          <Link to="/learn/glossary">Glossary</Link>
        </li>
        <li>
          <Link to="/learn/demos">Demonstration videos</Link>
        </li>
        <li>
          <Link to="/learn/lessons">Lesson videos</Link>
        </li>
      </ul>
    </Layout>
  );
};

export default Learn;
